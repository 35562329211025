// App.js
import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Foto from './foto.png';
import BG from './back.png';
import Logo from './logo.png';
import Icon1 from './icon1.png';
import Icon2 from './icon2.png';
import Icon3 from './icon3.png';
import Icon4 from './icon4.png';
import Icon5 from './icon5.png';
import Icon6 from './icon6.png';
import Icon7 from './icon7.png';
import Icon8 from './icon8.png';
import Icon9 from './icon9.png';
import Icon10 from './icon10.png';
import Icon11 from './icon11.png';
import Icon12 from './icon12.png';
import Icon13 from './icon13.png';
import Icon14 from './icon14.png';
import Icon15 from './icon15.png';
import Icon16 from './icon16.png';
import VITA from './vita.png';
import INSTA from './insta.png';
import WHATS from './whats.png';

library.add(faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook );

const ScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};


const UrologiaGeralPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Urologia geral</h2>
    <p>Você sabe quando deve buscar um atendimento médico com um urologista? Para começar, é importante entender que o urologista é o especialista que cuida tanto do sistema reprodutor masculino (testículos, pênis, próstata, etc.) quanto do sistema urinário de homens e mulheres, incluindo órgãos como rins, bexiga e uretra.</p>
    <p>Como a maioria desconhece em quais situações procurar um urologista, separei alguns sintomas que comumente servem como um sinal de alerta para você marcar uma consulta:</p>
    <ul>
      <li>Dor abdominal e lombar intensa;</li>
      <li>Dor no pênis, testículos ou epidídimo;</li>
      <li>Sintomas urinários como: aumento da frequência urinária, dor ao urinar, gotejamento, incontinência, sangue na urina, diminuição do fluxo urinário.</li>      
    </ul>
    <p>O urologista é o médico responsável por tratar de doenças como:</p>
    <ul>
      <li>Cistite e pielonefrite;</li>
      <li>Inflamações genitais;</li>
      <li>Tumores benignos ou malignos (câncer urológico);</li>    
      <li>Cálculos renais;</li>  
      <li>Incontinência ou obstrução do fluxo urinário;</li>  
      <li>Infertilidade masculina;</li>  
      <li>Ejaculação precoce;</li>  
      <li>Impotência sexual;</li>  
      <li>Presença de infecções sexualmente transmissíveis;</li>  
      <li>Fimose e curvatura peniana;</li>  
      <li>Cânceres que acometem o sistema reprodutor masculino.</li>  
    </ul>
    <p>Ao notar qualquer anormalidade associada ao sistema reprodutor masculino ou ao sistema urinário é fundamental buscar o quanto antes uma avaliação médica. Cuide-se mais e viva melhor! </p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const AndrologiaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Andrologia</h2>
    <p>A andrologia, também conhecida como Medicina Sexual, é a área da Urologia na qual se avalia e trata as doenças do sistema reprodutivo masculino.</p>
    <p>Ela atua, principalmente, em problemas como: infertilidade masculina, disfunções sexuais, impotência sexual, distúrbios ejaculatórios, e distúrbios hormonais masculinos. Ela também é responsável pelos procedimentos de fertilização, bem como realização ou reversão de vasectomia.</p>
    <p>É recomendado que os homens se consultem periodicamente com um especialista em Andrologia, a fim de prevenir, diagnosticar e, se necessário, obter um tratamento eficaz para o seu quadro.</p>
    <p>O Dr. Leonardo é especialista em sexualidade humana pela Universidade de São Paulo e Fellow do Comitê Europeu de Medicina Sexual.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const LitiaseUrinariaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Litiase Urinária</h2>
    <p>A litíase urinária, também conhecida como urolitíase, é uma doença que ocorre devido à formação de cálculos no aparelho urinário, as famosas "pedras nos rins".</p>
    <p>Os cálculos urinários são estruturas sólidas que resultam da aglomeração de cristais. Esses cálculos podem provocar muita dor e incômodo, assim como náuseas, vômitos, hematúria e, eventualmente, calafrios e febre.</p>
    <p>O tratamento para esse quadro depende de vários fatores, como o tamanho e localização do cálculo, se traz alguma consequência, como obstrução, dor ou infecção, o grau de dureza deste cálculo e os antecedentes do paciente (se já operou alguma vez para retirar pedra, se já eliminou espontaneamente etc.).</p>
    <p>O tratamento cirúrgico quando indicado se faz atualmente por via endoscópica através do auxílio do laser. Devido sua prevalência na população é um dos procedimentos mais realizados na prática diária.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const UroOncologiaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Uro Oncologia</h2>
    <p>A Uro-Oncologia, ou Urologia Oncológica, é uma subespecialidade da urologia. Ela é responsável pelo estudo, prevenção, diagnóstico, tratamento e acompanhamento de tumores no sistema urinário, como Câncer de Próstata, Bexiga, Rim, Testículos e Pênis.</p>
    <p>Além disso, a Uro-Oncologia também se destaca pela permanente evolução dos métodos de diagnóstico, dos exames de imagem, das técnicas cirúrgicas e dos tratamentos oncológicos complementares.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const CirurgiaRoboticaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Cirurgia Robotica</h2>
    <p>Atualmente, a cirurgia robótica é a técnica mais moderna entre os procedimentos minimamente invasivos. Conduzida por cirurgiões especialistas, ela é realizada por meio da manipulação de um robô para realizar incisões, ressecções e reconstruções.</p>
    <p>A cirurgia robótica vem se consolidando como padrão no tratamento de muitos quadros na urologia, na qual ela demonstra ser a melhor técnica para a cirurgia de câncer de próstata (prostatectomia radical) e rim (nefrectomia parcial ou nefrectomia radical), e também para cirurgias reconstrutivas, como pieloplastia para obstrução do rim.</p>
    <p>Há muitas vantagens nesta técnica, tanto para o cirurgião quanto para o paciente. Para o médico, a precisão dos movimentos, melhor visualização do campo cirúrgico e a facilidade na manipulação dos instrumentos, são alguns dos muitos benefícios da cirurgia robótica. Já para o paciente, essa cirurgia promove menor tempo de internação, menos dor no pós-operatório, menor sangramento, reduz o risco de infecções e requer menos tempo de recuperação, o que possibilita o retorno mais rápido à rotina normal. Em 2017, o Dr. Leonardo Lins realizou treinamento e certificação em cirurgia robótica pela Intuitive Surgical e está apto a realizar procedimentos urológicos com auxílio desta tecnologia.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const TerapiaDeReposicaHormonalPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Terapia de Reposica Hormonal</h2>
    <p>A Terapia de Reposição Hormonal com Testosterona (TRT) é a mais utilizada no tratamento dos homens com déficit de hormônio masculino. Seu principal objetivo é restabelecer os níveis normais da testosterona e diminuir os sintomas relacionados ao hipogonadismo.</p>
    <p>Por volta dos 40 anos de idade, os níveis de testosterona começam a cair, o que causa a diminuição da libido, dificuldade de ereção, perda de pelos, diminuição da massa muscular, aumento da irritabilidade, falta de disposição, insônia e até ganho de peso. A reposição hormonal, bem indicada, pode controlar esses sintomas melhorando a qualidade de vida do paciente.</p>
    <p>A presença destes sintomas deve motivar a ida a um urologista. O profissional analisará individualmente caso a caso e com auxílio de uma avaliação hormonal completa, poderá indicar ou não reposição hormonal. Há várias opções de tratamento para a Reposição Hormonal, porém, apenas o médico pode indicar o mais eficaz para o quadro do paciente. Dentre as opções estão:</p>
    <ul>
      <li>Medicações via oral;</li>
      <li>Medicações injetáveis de curta e longa duração;</li>
      <li>Géis transdérmicos;</li>
      <li>Adesivos;</li>
      <li>Implantes subcutâneos de testosterona.</li>
    </ul>
    <p>Além de aumentar os níveis da Testosterona e melhorar os sintomas relacionados com a sua queda, outros benefícios da reposição hormonal são: controle do metabolismo ósseo, cardioproteção e efeitos positivos no sistema nervoso central.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const CheckUpUrologicoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Check-Up Urológico</h2>
    <p>O check-up urológico é o principal aliado na prevenção de doenças e na qualidade de vida do homem.</p>
    <p>Durante a infância, para citar um exemplo, a consulta com um urologista é importante para analisar se há e se é necessário a cirurgia de fimose. Já na adolescência, a consulta é necessária para analisar o desenvolvimento dos órgãos genitais, conversar sobre a prevenção de ISTs e o início da vida sexual e em alguns casos, orientar sobre problemas como infecção urinária.</p>
    <p>Com o passar dos anos, entram os cuidados com a saúde e a prevenção de patologias, como disfunções miccionais, disfunções sexuais, problemas hormonais, infecções urinárias, câncer de próstata e etc., que demandam exames anuais, popularmente conhecido como check-up urológico.</p>
    <p>Dentre os principais exames, estão:</p>
    <ul>
      <li>Exame de sangue: Forma laboratorial de avaliar o metabolismo do indivíduo e como está a bioquímica do seu organismo.</li>
      <li>Exame de urina: Importante para avaliar a saúde renal. Dentre outros fatores, ele avalia a eventual presença de infecções, sangue na urina e indícios de cálculo renal.</li>
      <li>Exame de próstata: O exame de toque retal é ainda o mais indicado para a detecção de alguma alteração na próstata, como por exemplo nódulos que possam indicar a presença de câncer.</li>
      <li>Sorológico: Exame ideal para acompanhar os anticorpos para as diversas ISTs que, muitas vezes, são silenciosas.</li>
      <li>Ultrassom abdominal total: Exame de imagem para avaliação dos órgãos intra-abdominais</li>
    </ul>
    <p>O check up urológico não tem nenhum mistério e, quando realizado frequentemente, pode ser um grande aliado da saúde, do bem-estar e da qualidade de vida dos homens.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const ImplantesHormonaisPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Implantes Hormonais</h2>
    <p>O implante hormonal, ou implante subcutâneo de testosterona é uma das alternativas de tratamento para a reposição hormonal do homem.</p>
    <p>A partir dos 40 anos de idade, é natural que um percentual significativo de homens apresente uma redução nos níveis de testosterona. Sinais, como diminuição da força muscular, queda na libido, disfunção erétil e até a redução da disposição geral, indicam a necessidade da realização de exames para obter um diagnóstico e orientação para um tratamento assertivo.</p>
    <p>Ao constatar a baixa da normalidade dos níveis desse hormônio no indivíduo, o médico vai explicar ao paciente qual deverá ser o nível de reposição para o caso dele, de acordo com seu grau de necessidade, e escolher uma das formas de reposição de testosterona.</p>
    <p>Há uma variedade de opções para repor a testosterona, porém, uma das opções mais modernas e eficazes disponíveis atualmente são os implantes de pequenos pellets de testosterona.</p>
    <p>O procedimento da implantação é rápido, normalmente é realizado no consultório, e apenas uma anestesia local é necessária para amenizar a sensação de dor. Os pellets podem ser implantados nos flancos ou nos glúteos, e após serem introduzidos, eles liberam o hormônio da testosterona gradativamente por até seis meses. Como são absorvíveis, não necessitam de um novo procedimento para a retirada deles.</p>
    <p>Quando são implantados por um profissional qualificado, e conforme a necessidade do quadro, os implantes hormonais melhoram a saúde sexual e a capacidade erétil, aumentam a energia, fortalecem os músculos, proporcionam uma melhora na performance esportiva, promovem a sensação de bem-estar geral. Além disso, a reposição hormonal é fundamental para o bom funcionamento do metabolismo cardiovascular, ósseo e metabólico como um todo.</p>
    <p>É importante lembrar que é fundamental buscar um especialista para definir, junto com o paciente, qual é o tratamento mais adequado para o seu quadro. A reposição hormonal independente da forma que é realizada requer além de indicação precisa e individualizada, um acompanhamento regular para avaliação de sintomas, exames laboratoriais e eventuais efeitos adversos.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const CirurgiasPenianaPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Cirurgias Peniana</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const CirurgiasEndourologicasPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Cirurgias Endourologicas</h2>
    <p>As cirurgias endourológicas são alguns dos procedimentos mais eficazes na eliminação dos cálculos renais, popularmente conhecidos como pedras nos rins.</p>
    <p>Dentre as técnicas cirúrgicas mais utilizadas estão:</p>
    <h2>Ureteronefrolitotripsia endoscópica laser</h2>
    <p>Nesta técnica, utiliza-se um instrumento chamado ureteroscópio, que pode ser semi-rígido ou flexível, e que é introduzido através da uretra, para fragmentar e remover os cálculos com auxílio de uma fibra de laser. Os cálculos podem estar na bexiga, nos ureteres ou nos rins. Outro instrumento, chamado basket, também é introduzido no interior do ureteroscópio, para remover os fragmentos dos cálculos. Não há incisões e a recuperação é relativamente rápida.</p>
    <h2>Cirurgia renal percutânea</h2>
    <p>Diferente da ureteroscopia, nesta técnica há necessidade de realizar uma pequena incisão na região lombar de aproximadamente 2 cm por onde se acessa o rim também com auxílio de uma pequena câmara chamada de nefroscópio. Acoplada a esta câmara utilizamos uma fonte de energia capaz de triturar o cálculo e ao mesmo tempo aspirar os seus fragmentos. A cirurgia renal percutânea é geralmente recomendada para cálculos volumosos situados no rim.</p>
    <p>As cirurgias endourológicas são necessárias para casos sintomáticos de cálculos urinários ou mesmo para cálculos assintomáticos que possuem baixa probabilidade de eliminação espontânea. O tipo de cirurgia a ser realizada irá depender do número, localização e tamanho do cálculo. Por se tratar de cirurgias minimamente invasivas, o tempo de internação é curto, as vezes de 1 dia apenas, e a recuperação é rápida.</p>
    <p>Sendo assim, o ideal não é esperar ter uma cólica renal para procurar o urologista, principalmente se há casos de cálculo renal na família. A ultrassonografia abdominal pelo menos uma vez ao ano é importantíssima para diagnosticar casos ainda silenciosos.</p>
    <p>A ida ao urologista é fundamental não apenas para se estabelecer um eventual tratamento cirúrgico, mas para se identificar possíveis causas da formação das pedras e atuar preventivamente.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const CauterizaçãoDoHpvPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Cauterização do HPV</h2>
    <p>A cauterização das verrugas genitais e das lesões no colo do útero é considerado um dos tratamentos mais eficazes dos sintomas do contágio pelo HPV.</p>
    <p>Neste procedimento, o especialista elimina as verrugas e lesões ocasionadas pelo HPV através de diversas fontes de energia como laser, eletrocoagulação e crioterapia no próprio consultório ou em ambiente hospitalar.</p>
    <p>Além de tratar as lesões, o objetivo desse procedimento é permitir que as células saudáveis se desenvolvam na região e, por consequência, evitar o desenvolvimento da doença.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const UsgDopplerPenianoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>USG Doppler Peniano</h2>
    <p>O USG Doppler Peniano é um exame de ultrassonografia realizado após a indução farmacológica de ereção e que permite observar o aumento ou diminuição da vascularização na região, além da velocidade do sangue dentro dos vasos sanguíneos que nutrem o pênis.</p>
    <p>Ele é um dos exames mais recomendados para a investigação das causas da disfunção erétil. Apesar da indução da ereção ser invasiva, ela é bem tolerada, não tem radiação, não gera nenhum efeito colateral e permite estudar o órgão sexual e investigar possíveis doenças.</p>
    <p>Além das causas de disfunção erétil, o exame permite avaliar outras patologias na região, entre elas:</p>
    <ul>
      <li>Doença de Peyronie;</li>
      <li>Traumas;</li>
      <li>Doenças vasculares;</li>
      <li>Priapismo de alto fluxo.</li>
    </ul>
    <p>O USG Doppler peniano pode ser realizado em consultório, juntamente com o teste de ereção, no qual substâncias vasodilatadoras são aplicadas diretamente no pênis, na parte lateral, por uma agulha fina, o que torna este procedimento pouco doloroso.</p>
    <p>Conforme as substâncias agem na região, é possível captar as imagens do fluxo sanguíneo por meio de um aparelho de ultrassonografia que é colocado sobre o pênis. Com as imagens, o médico consegue investigar o caminho do fluxo sanguíneo. A partir deste e outros exames, caso sejam necessários, é possível definir o tratamento ideal para a disfunção erétil do paciente.</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const OutrosProcedimentosPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Outros Procedimentos</h2>
    <p>...</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const SobrePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Sobre</h2>
    <p>Gostaria de contar hoje para vocês um pouco mais sobre mim e como a urologia entrou na minha vida.</p>
    <p>Foi na Faculdade de Medicina do ABC (FMBC), onde me formei, que os excelentes professores vinculados à disciplina de urologia me despertaram o interesse na área. Por ser uma disciplina com um campo de atuação vasto e diverso com possibilidades grandes de desenvolvimento, não demorou muito para eu entender que era nessa carreira que eu gostaria de seguir.</p>
    <p>A pluralidade de doenças que a urologia trata, como infecções sexualmente transmissíveis (ISTs), aumento benigno da próstata, disfunções sexuais e miccionais, infertilidade, patologias como câncer de próstata, de bexiga, de rim e de pênis dentre inúmeras outras, além de uma variedade de cirurgias urológicas, como as abertas, minimamente invasivas e endoscópicas, foram alguns dos fatores que me encantaram e me ajudaram a tomar a decisão de me especializar na área.</p>
    <p>Fora isso, o legal da urologia é que muitas patologias possuem fortes aspectos emocionais envolvidos o que me permite acessar o paciente de forma mais complexa, independente do gênero, idade ou orientação sexual e isso, definitivamente, é o que me orgulha e me motiva, diariamente, a atuar na área. Gosto de gente e de cuidar de gente.</p>
    <p>Olhando para trás, com certeza eu não faria uma escolha diferente! </p>
    <h2>Dr. Leonardo M. M. Lins - Urologista geral</h2>
    <ul>
      <li>Formação e residência médica na Faculdade de Medicina do ABC;</li>
      <li>Urologista com título de especialista pela Sociedade Brasileira de Urologia;</li>
      <li>Assistente da disciplina de urologia da FMABC;</li>
      <li>Especialista em sexualidade pela FMUSP;</li>
      <li>Fellow do Comitê Europeu de Medicina Sexual (2020);</li>
      <li>Membro da Sociedade Americana de Urologia (AUA) e da Sociedade Internacional de Medicina Sexual.</li>
    </ul>
    <a target='_blank' href="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?metodo=apresentar&id=K4587037E3">Acesse meu Currículo Lattes</a>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const UrologiaEdiversidadePage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Urologia e Diversidade</h2>
    <p>Sempre reforço em meu consultório o quanto é importante uma conversa franca e detalhada entre médico e paciente, com o intuito de esclarecer durante a consulta urológica todas as dúvidas que possam surgir.</p>
    <p>Por este motivo, decidi criar este perfil profissional nas redes sociais, como um canal aberto para responder a questionamentos comuns dos pacientes que buscam um urologista, dar dicas de saúde e prevenção e falar abertamente de assuntos tidos como tabus, sem preconceitos e sem julgamentos.</p>
    <p>Meu objetivo aqui é trazer conteúdos relevantes sobre saúde urológica e sexual, de forma simples e prática, tanto para homens, quanto para mulheres (sim, o urologista também é o médico delas) e especialmente para o público LGBTQIA+.</p>
    <p>Assim como faço em meu consultório, quero criar neste perfil um ambiente propício para o conhecimento em saúde, com inclusão, acolhimento e respeito pela diversidade. Então, se você procura informação de qualidade, com uma linguagem descomplicada e preparada para você, acho que este pode ser o lugar!</p>
    <p>Acompanhe nossas próximas postagens nas redes sociais. </p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);
const FaleConoscoPage = () => (
  <div style={{ padding: '0px 30px 30px 30px' }}>
    <h2 style={{ textAlign: 'center' }}>Fale Conosco</h2>
    <p>Rua Mario Amaral, 172 – Paraíso, São Paulo - SP</p>
    <p>Fone: +55 (11) 3884-8013</p>
    <p>WhatsApp: +55 (11) 95584-2021</p>
    <p>E-mail: contato@drleonardolins.com.br</p>
    <div style={{ textAlign: 'center', margin: '70px 0px 0px 0px',  padding: '10px', border: '2px solid #cbc2a5', borderRadius: '10px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);







const App = () => (
  <Router>
    <div style={{ backgroundImage: `url(${BG})`, height: '100%', minHeight: '100vh', backgroundRepeat  : 'no-repeat', backgroundPosition: 'top, center', backgroundSize: 'cover'}}>
      <header style={{ textAlign: 'center' }}>
      <Link to="/"><img src={Logo} alt="Leonardo M. M. Lins" title="Leonardo M. M. Lins" style={{ width: '180px', marginTop: '20px' }} /></Link>
      </header>
      
      
      
      <Routes>
      
          <Route path="/" element={

<div>

<div style={{ textAlign: 'center' }}><img src={Foto} alt="Leonardo M. M. Lins" title="Leonardo M. M. Lins" style={{ width: '180px', marginTop: '20px', borderRadius: '100px' }} /></div>
  

<h2 style={{ textAlign: 'center', margin: '30px 30px 0px 30px', color: '#cbc2a5' }}>Saiba mais sobre Nosso Novo Projeto:</h2><br></br>
<div className="item2" style={{ textAlign: 'center' }}>
  
  <img src={VITA} alt="" title="" style={{ width: '250px' }} />


</div>
<div className="item2" style={{ textAlign: 'center' }}>
  

  <img src={INSTA} alt="" title="" style={{ width: '250px' }} />


</div>
<div className="item2" style={{ textAlign: 'center' }}>
  
  
  <img src={WHATS} alt="" title="" style={{ width: '250px' }} />

</div>
  <h2 style={{ textAlign: 'center', margin: '30px 30px 0px 30px', color: '#cbc2a5' }}>Conheça minhas Áreas de Atuação:</h2><br></br>
  
          
          <div className="container1">
        
           
              <div className="item">
                <Link to="/urologiaGeral" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon1} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Urologia geral</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/andrologia" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon2} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Andrologia</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/litiaseUrinaria" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon3} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Litíase urinária</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/uroOncologia" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon4} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Uro-oncologia</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/cirurgiaRobotica" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon5} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Cirurgia robótica</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/terapiaDeReposicaHormonal" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon6} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Terapia de reposição hormonal</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/checkUpUrologico" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon7} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Check-up urológico</small>
                </Link>
              </div>
              <div className="item">
                <Link to="/implantesHormonais" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Icon8} alt="" title="" style={{ width: '50px' }} />
                  </div>
                  <small style={{ fontSize: '11px' }}>Implantes hormonais</small>
                </Link>
              </div>
             
              
          
          </div>
          
          
          <h2 style={{ textAlign: 'center', margin: '30px 30px 0px 30px', color: '#cbc2a5' }}>Conheça os Procedimentos:</h2><br></br>
          


          <div className="container2">
        
           
        <div className="item">
          <Link to="/cirurgiasPeniana" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon9} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Cirurgias peniana</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/cirurgiasEndourologicas" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon10} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Cirurgias endourológicas</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/cauterizaçãoDoHpv" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon11} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Cauterização do HPV</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/UsgDopplerPeniano" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon12} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>USG doppler peniano</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/outrosProcedimentos" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon13} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Outros procedimentos</small>
          </Link>
        </div>
        
       
        
    
    </div>



    <h2 style={{ textAlign: 'center', margin: '30px 30px 0px 30px', color: '#cbc2a5' }}>Outros Links</h2><br></br>

    
    <div className="container3">
        
           
   
        <div className="item">
          <Link to="/sobre" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon14} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Sobre Dr. Leonardo</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/urologiaEdiversidade" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon15} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Urologia e diversidade</small>
          </Link>
        </div>
        <div className="item">
          <Link to="/faleConosco" className="black-link" onClick={ScrollToTop}>
            <div style={{ marginBottom: '10px' }}>
            <img src={Icon16} alt="" title="" style={{ width: '50px' }} />
            </div>
            <small style={{ fontSize: '11px' }}>Fale conosco</small>
          </Link>
        </div>
        
       
        
    
    </div>
          
          </div>
          } />
          <Route path="/urologiaGeral" element={<UrologiaGeralPage />} />
          <Route path="/andrologia" element={<AndrologiaPage />} />
          <Route path="/litiaseUrinaria" element={<LitiaseUrinariaPage />} />
          <Route path="/uroOncologia" element={<UroOncologiaPage />} />
          <Route path="/cirurgiaRobotica" element={<CirurgiaRoboticaPage />} />
          <Route path="/terapiaDeReposicaHormonal" element={<TerapiaDeReposicaHormonalPage />} />
          <Route path="/checkUpUrologico" element={<CheckUpUrologicoPage />} />
          <Route path="/implantesHormonais" element={<ImplantesHormonaisPage />} />
          <Route path="/cirurgiasPeniana" element={<CirurgiasPenianaPage />} />
          <Route path="/cirurgiasEndourologicas" element={<CirurgiasEndourologicasPage />} />
          <Route path="/cauterizaçãoDoHpv" element={<CauterizaçãoDoHpvPage />} />
          <Route path="/UsgDopplerPeniano" element={<UsgDopplerPenianoPage />} />
          <Route path="/outrosProcedimentos" element={<OutrosProcedimentosPage />} />
          <Route path="/sobre" element={<SobrePage />} />
          <Route path="/urologiaEdiversidade" element={<UrologiaEdiversidadePage />} />
          <Route path="/faleConosco" element={<FaleConoscoPage />} />
         
          {/* Add routes for other items as needed */}
      </Routes>
      <footer style={{ textAlign: 'center', padding: '20px', color: '#cbc2a5' }}>
        Rua Mario Amaral, 172 - Paraíso, São Paulo - SP
      </footer>
    </div>
  </Router>
);

export default App;















// function App() {
//   return (
//     <div style={{ margin: '27px' }}>
//       <header>
//       <div style={{ textAlign: 'center' }}><img alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" src={Foto} style={{ width: '200px' }} /></div>
//         <p className="gradient">A menopausa não será mais um problema para você!</p>
//         <p>Com o MenoPlus 360, você terá acompanhamento frequente e o cuidado próximo de um médico especializado, para superar os sintomas desta fase.</p>
//         <div style={{ textAlign: 'center' }}><a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img src={Bot} alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" style={{ width: '250px', borderRadius: '10px' }} />
//         </a>
//         </div>
//         </header> 
//         <p className="gradient">Volte a viver como antes:</p>
//         <ul>
//           <li>Sem sofrer com o calor insuportável;</li>
//           <li>Satisfeita com seu relacionamento íntimo;</li>
//           <li>Livre da dificuldade para emagrecer;</li>
//           <li>Disposta para as atividades do dia a dia;</li>
//           <li>Bem-humorada e de bem com a vida.</li>
//         </ul>
//         <p className="gradient">O Protocolo MenoPlus 360 é para um único tipo de mulher:</p>
//         <p>A mulher que não aguenta mais viver refém dos sintomas da menopausa e que deseja recuperar o controle da própria vida.</p>
//         <div style={{ textAlign: 'center' }}>
//           <a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" src={Bot} style={{ width: '250px', borderRadius: '10px' }} />
//           </a>
//         </div>
//         <p className="gradient">O Protocolo MenoPlus 360 te dá direito a:</p>
//         <ul>
//           <li><b>Protocolo Personalizado</b> - Pensado exclusivamente para você, para resolver o seu problema de uma vez por todas;</li>
//           <li><b>Acompanhamento Mensal durante 3 meses</b> - Em grupo, com praticidade e agilidade;</li>
//           <li><b>Comunidade no Whatsapp</b> - Uma verdadeira rede de apoio entre mulheres com o mesmo objetivo em comum;</li>
//           <li><b>2 sessões de Consultoria com Nutricionista</b> - Porque o cuidado com a alimentação é primordial nessa importante fase da mulher;</li>
//           <li><b>2 sessões de Consultoria com Personal Trainer</b> - Para que você trabalhe os exercícios corretos e recomendados para o seu corpo.</li>
//         </ul>
//         <p>Eu quero ajudar você a se libertar dos sintomas da menopausa e por isso quero que tenha acesso a:</p>
//         <p>Protocolo Personalizado (No valor de R$2.500,00)</p>
//         <p>Acompanhamento Mensal em Grupo (No valor de R$600,00)</p>
//         <p>Comunidade no Whatsapp (No valor de R$2.500,00)</p>
//         <p>2 Sessões de consultoria com Nutricionista (No valor de R$500,00)</p>
//         <p>2 sessões de consultoria com Personal Trainer (No valor de R$500,00)</p>
//         <p>Não por R$5.300,00 (que é o que vale), mas sim</p>
//         <p>Por Apenas</p>
//         <p>12x de R$199,70</p>
//         <p>Apenas nas primeiras 72h</p>
//         <p>Um investimento muito baixo, se comparado à oportunidade de recuperar sua vida como era antes da menopausa.</p>
        
//         <div style={{ textAlign: 'center' }}><a href="https://sun.eduzz.com/2178492" target="_blank" rel="noreferrer">
//           <img src={Dr} alt="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" title="QUERO MINHA VIDA SEM MENOPAUSA DE VOLTA" style={{ width: '250px', borderRadius: '10px' }} />
//         </a>
//         </div>
       
//         <p>Muito prazer,</p>
//         <p>Sou o Dr. Leonardo M. M. Lins, médico, com mais de 5 mil atendimentos realizados e graduado com experiência na área de nutrologia, implantes e terapias injetáveis.</p>
//         <p>Meu principal objetivo é cuidar da saúde e do bem-estar de mulheres que desejam uma vida mais saudável e plena após os 40 anos.</p>
//         <p>Minha jornada profissional começou a partir de uma experiência pessoal, na qual superei um quadro de sobrepeso e depressão, tendo nessa vivência, a inspiração necessária para buscar conhecimento e poder oferecer tratamentos personalizados e eficazes.</p>
//         <p>Acredito na medicina integrativa e costumo olhar para as minhas pacientes de maneira ampla, pois entendo que elas não buscam um tratamento por estarem doentes, mas sim, porque desejam viver mais e melhor.</p>
        
//         <p>Atenção: Nós não compartilharemos seu contato com terceiros. O tratamento de dados nesta empresa acontece em conformidade com a Lei Geral de Proteção de Dados (LGPD). Você está seguro(a) e poderá se descadastrar a qualquer momento.</p>
//         <p>Política de Privacidade - Termos de Uso © Dr. Leonardo M. M. Lins - Todos os Direitos Reservados</p>
//       </div>
//   );
// }

// export default App;
